import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {v4 as uuidv4, validate as uuidValidate} from 'uuid'
import * as Sentry from "@sentry/react";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

// optional configuration
const options = {
    // you can also just use 'bottom center'
    position: positions.MIDDLE,
    timeout: 3000,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.SCALE
}


const params = new URLSearchParams(window.location.search)
const id = params.get("id")
const revisionId = parseInt(params.get("revision_id"))
const token = params.get("token")
const lat = parseFloat(params.get("lat"))
const lng = parseFloat(params.get("lng"))
window.vrDebug = params.has("debug")

window.getLocation = function (callback) {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position){
            console.log('navigator location:', position.coords)
            callback(position.coords.latitude, position.coords.longitude)
        }, function (){
            console.log('navigator location error:', lat,lng)
            callback(lat,lng)
        })
    } else {
        console.log('no navigator location:', lat,lng)
        callback(lat, lng)
    }
}

function refresh() {
    window.location.search = "?id=" + uuidv4() + "&revision_id=" + revisionId + "&token=" + token + "&lat="+lat +"&lng="+lng;
}

if (!uuidValidate(id)) {
    refresh()
}

Sentry.init({
    dsn: "https://9721a4b7d925e3a672ddfd1905ca2139@o4506200971608064.ingest.sentry.io/4506200974229504",
    integrations: [new Sentry.BrowserTracing()],

    tracesSampleRate: 1.0
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    id &&
    <AlertProvider template={AlertTemplate} {...options}>
        <App id={id} refresh={refresh} headers={{Authorization:token}} revisionId={revisionId}/>
    </AlertProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
